import React, { Component } from 'react'
// import styled from 'styled-components';

import SEO from '../components/SEO/SEO'
import Layout from '../Layout/Layout'
import Hero from '../components/Hero/Hero'
import Header from '../components/Header/Header'
import Paragraph from '../components/Paragraph/Paragraph'
import Container from '../components/Container/Container'

import Thumb6 from '../assets/images/thumb6.jpg'
import Mapa from '../assets/images/mapa.png'

class CennikPage extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Cennik | Zwyżka, podnośnik, Stalowa Wola i okolice"
          keywords={[
            'cennik',
            'wynajem zwyżki',
            'podnośnik z operatorem',
            'jeżowe',
            'nisko',
            'mielec',
            'kolbuszowa',
          ]}
        />
        <Hero image={Thumb6}>
          <Header>Cennik</Header>
        </Hero>
        <Header isBig>Zadzwoń do nas</Header>
        <Container columns={2}>
          <div>
            <img
              src={Mapa}
              alt="Mapa Polski z zaznaczonymi miejscowościami: Rzeszów, Stalowa Wola, Tarnobrzeg, Mielec, Sandomierz, Nisko, Nowa Dęba..."
            />
          </div>
          <div>
            <Paragraph>
              Centrala naszej firmy znajduje się w Bojanowie, który
              usytuowany jest pomiędzy miejscowościami: Stalowa Wola,
              Tarnobrzeg, Sandomierz, Nisko, Nowa Dęba, Kolbuszowa. Nasze
              usługi świadczymy jednak na znacznie większym obszarze.
            </Paragraph>
            <Paragraph>
              W celu omówienia szczegółów realizacji usługi prosimy o
              kontakt telefoniczny. W trakcie rozmowy ustalimy szczegółowy
              plan, termin oraz cenę zlecenia.
            </Paragraph>
            <Paragraph>Nasz numer telefonu: +48 788 716 154</Paragraph>
          </div>
        </Container>
      </Layout>
    )
  }
}

export default CennikPage
